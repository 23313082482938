import MobileAppLinks from '@/components/atoms/MobileAppLinks.vue'
import Icon from '@/components/atoms/Icon.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      socialLink: null
    }
  },
  components: {
    MobileAppLinks,
    Icon
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      socialProfile: 'auth/socialProfile'
    }),
    privateAppLink () {
      return process.env.VUE_APP_PRIVATEAPP_LINK
    },
    myHolaInfoLink () {
      return process.env.VUE_APP_MYHOLAINFO_LINK
    },
    nickname () {
      return this.$route.params.nickname
    }
  },
  async created () {
    if (this.nickname) {
      await this.getUser(this.nickname).catch((e) => {
        if (e.response.data.data.type === 'permission') {
          this.$toasted.error(this.$t('permissionMessage'))
          this.$router.replace({ name: 'auth', params: { nickname: '' } }).catch(() => {})
        }
        if (e.response.data.data.type === 'expired') {
          this.$toasted.error(this.$t('expiredMessage'))
        }
      })
      if (this.user && this.user.userId) {
        await this.getSocialSpace()
      }
    }
  },
  methods: {
    ...mapActions({
      getUser: 'auth/getUser',
      getSocialProfile: 'auth/getSocialProfile'
    }),
    async getSocialSpace () {
      await this.getSocialProfile(this.user.userId)
    },
    openQRCodePopup () {
      this.$modal.show('login-by-code')
    },
    openRegistrationPopup (title) {
      this.$modal.show('registration', { title: title })
    },
    onShareContact () {
      if (navigator.share) {
        navigator.share({
          title: '',
          text: this.user.phone ? this.user.phone : '',
          url: ''
        })
          .catch(function () {
            console.log('Sharing failed')
          })
      } else {
        this.copyURLToClipBoard()
      }
    },
    async copyURLToClipBoard () {
      try {
        await navigator.clipboard.writeText(`${this.myHolaInfoLink}${this.user.nickname}`)
        this.$toasted.success(this.$t('linkCopied'))
      } catch (e) {
        console.log(e)
      }
    },
    deeplink () {
      const ua = navigator.userAgent.toLowerCase()
      const isAndroid = ua.indexOf('android') > -1
      const isIphone = ua.indexOf('iphone') > -1
      if (isIphone === true) {
        window.open(
          `${process.env.VUE_APP_IOS_LINK}`,
          '_blank'
        )
      } else if (isAndroid === true) {
        window.open(
          `${process.env.VUE_APP_ANDROID_LINK}`,
          '_blank'
        )
      } else {
        window.open(
          `${process.env.VUE_APP_ANDROID_LINK}`,
          '_blank'
        )
      }
    },
    goSocial () {
      if (this.socialProfile.length) {
        window.open(
          `${process.env.VUE_APP_SOCIAL_LINK}${this.socialProfile[0].nickname}`,
          '_blank'
        )
      } else {
        window.open(
          `${process.env.VUE_APP_SOCIAL_LINK}`,
          '_blank'
        )
      }
    }
  }
}
