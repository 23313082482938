import apiReq from './request'

const BASE_URL = `${process.env.VUE_APP_ROOT_API}/auth`
const USER_URL = `${process.env.VUE_APP_ROOT_API}/user`
const SOCIAL_URL = `${process.env.VUE_APP_ROOT_API}/social`
export const REFRESH_TOKEN_URL = `${BASE_URL}/refresh_token`

export default {
  refreshToken (data) {
    return apiReq.post(REFRESH_TOKEN_URL, data)
  },
  logout (data) {
    return apiReq.post(`${BASE_URL}/user/logout`, data)
  },
  authByCode (data) {
    return apiReq.post(`${BASE_URL}/user/qr-login?fingerprint=${data.fingerprint}`, data.data)
  },
  sendQRCode (data) {
    return apiReq.post(`${BASE_URL}/user/qr-code`, data)
  },
  register (data) {
    return apiReq.post(`${BASE_URL}/v4BPx8kxPUM5utBksHOg`, data)
  },
  getUser (nickname) {
    return apiReq.get(`${USER_URL}/info/user?value=${nickname}`, nickname)
  },
  getSocialProfile (holaId) {
    return apiReq.get(`${SOCIAL_URL}/profile/open/hola-list/${holaId}/`, holaId)
  }
}
