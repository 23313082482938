<template>
  <label class="form-container" :class="{'input-label': !multiline}">
    <template v-if="isLabelArray">
      <div v-for="(l, i) in label" :key="i" class="form--label">{{ l }}</div>
    </template>
    <div v-else-if="label" class="form--label">{{ label }}</div>
    <input
      v-if="!multiline"
      :type="type"
      v-bind="$attrs"
      v-on="inputListeners"
      :value="value"
      :class="['form--input', { 'input--error': invalid }]"
    >
    <textarea
      v-else
      :type="type"
      v-bind="$attrs"
      v-on="inputListeners"
      :value="value"
      :class="['form--textarea', { 'input--error': invalid }]"
    />
    <span
      v-show="!invalid && helpText"
      class="input--help-text"
    >{{ helpText }}</span>
    <span v-show="invalid" class="input--error-msg">
      <span>{{ error }}</span>
    </span>
  </label>
</template>

<script>
export default {
  name: 'base-input',
  // Disable automatic attribute inheritance, so that $attrs are
  // passed to the <input>, even if it's not the root element.
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,
  // Change the v-model event name to `update` to avoid changing
  // the behavior of the native `input` event.
  // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
  // model: {
  //   event: 'input'
  // },
  props: {
    invalid: {
      type: Boolean,
      default: false
    },
    error: {
      type: String
    },
    label: {
      type: [String, Array]
    },
    value: {},
    type: {
      type: String,
      default: 'text',
      // Only allow types that essentially just render text boxes.
      validator (value) {
        return [
          'email',
          'number',
          'password',
          'search',
          'tel',
          'text',
          'url'
        ].includes(value)
      }
    },
    multiline: {
      type: Boolean,
      default: false
    },
    helpText: String
  },
  computed: {
    inputListeners () {
      return Object.assign({},
        this.$listeners,
        {
          input: event => {
            this.$emit('input', event.target.value)
          }
        }
      )
    },
    isLabelArray () {
      return Array.isArray(this.label)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-container{
  width: 100%;
}
.form--textarea {
  font-family: $mont;
  width: 100%;
  height: 150px;
  resize: none;
  color: #3b3b3b;
  display: block;
  font-size: 16px;
  padding: 7px 1px;
  border-bottom: 1px solid #5a5a5a;
  transition: border-color $trans;
  border-top: none;
  border-left: none;
  border-right: none;
  &::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #00b764;
  }
  &:focus{
    border-bottom: 1px solid #00b764;
  }
  &[rows] {
    height: auto;
  }
}
.input-label{
  padding: 15px 0;
  display: block;
}
.form--input{
  font-family: $mont;
  color: #3b3b3b;
  display: block;
  font-size: 16px;
  width: 100%;
  height: 40px;
  padding: 7px 1px;
  border-bottom: 1px solid #5a5a5a;
  background-color: hsla(0,0%,100%,0);
  border-top: none;
  border-left: none;
  border-right: none;
  &:focus{
    border-bottom: 1px solid #00b764;
  }
  &.input--error{
    border-bottom: 1px solid $red;
  }
}

.input--error-msg {
  font-size: 12px;
  color: $red;
}
.input--help-text {
  font-size: 12px;
}
</style>
