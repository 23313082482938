<template>
  <button :disabled="disabled"
          @click="changeEvent"
          :data-progress="progress ? 'true' : 'false'"
          :type="type"
          :data-color="color"
          class="main-button">
    <span class="main-button__label">
      <slot></slot>
    </span>
    <span class="main-button__loader">
      <loader />
    </span>
  </button>
</template>

<script>
import loader from '@/components/atoms/Loader.vue'

export default {
  name: 'base-button',
  data () {
    return {}
  },
  components: {
    loader
  },
  props: {
    label: {
      type: String,
      default: 'Submit'
    },
    progress: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'submit'
    },
    color: {
      type: String,
      default: 'yellow'
    }
  },
  methods: {
    changeEvent () {
      this.$emit('change-event')
    }
  }
}
</script>

<style lang="scss">
.main-button{
  position: relative;
  display: inline-block;
  height: 60px;
  padding: 10px;
  border: 0;
  outline: none;
  cursor: pointer;
  user-select: none;
  min-width: 150px;
  background: #00b764;
  transition: background-color $trans;
  border-radius: 4px;
  @include respondTo(600px){
    height: 45px;
    padding: 5px;
  }

  span{
    padding: 0;
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    color: $white;
    @include respondTo(600px){
      font-size: 14px;
      line-height: 18px;
    }
  }

  &[data-color='blue'] {
    background-color: $white
  }

  &[data-progress='true'] {
    pointer-events: none;

    .main-button__label{
      opacity: 0;
    }

    .main-button__loader {
      display: inline-block;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
    &:hover {
      background-color: #00b764;
    }
  }

  &:hover {
    background-color: #00924f;
  }

  &__label {
    font-size: 16px;
    color: #00b764;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1;
  }

  &__loader {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 0;
    background: #00b764;

    .preloader{
      height: 25px;
      width: 25px;
    }
    .loader{
      position: absolute;
      left: calc(50% - 15px);
      top: calc(50% - 15px);
    }
  }

  &__loader-inner {
    display: inline-block;
    height: 3em;
    width: 3em;
    font-size: 8px;
    text-indent: -9999em;
  }
}
</style>
