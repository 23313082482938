<template>
  <div id="app" class="app">
    <div class="app-container">
      <AppBanner />
      <div class="wrapper">
        <div class="wrapper-top">
          <AppHeader/>
          <div class="wrapper-content">
            <router-view/>
          </div>
        </div>
        <div class="wrapper-bottom">
          <AppFooter/>
        </div>
      </div>
    </div>
    <LoginByCode/>
    <Registration/>
  </div>
</template>

<!--// global styles-->
<style src="@/assets/scss/main.scss" lang="scss"></style>

<script>
import AppHeader from '@/components/features/AppHeader.vue'
import AppFooter from '@/components/features/AppFooter.vue'
import AppBanner from '@/components/features/AppBanner.vue'
import LoginByCode from '@/components/modals/LoginByCode.vue'
import Registration from '@/components/modals/Registration.vue'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    AppBanner,
    LoginByCode,
    Registration
  }
}
</script>
