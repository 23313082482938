export default {
  // footer
  termOfService: 'Laufzeit der Dienstleistungen',
  privacyPolicy: 'Datenschutz-Bestimmungen',
  xCommunications: 'xKommunikation',
  xCommunicationsInvestorRelations: 'Anlegerbeziehungen',
  xCommunicationsMediaRequest: 'Medienanfrage',
  xCommunicationsOurPositionPrivacy: 'Unsere Position zum Datenschutz',
  xCommunicationsKnowledgeBase: 'Wissensbasis',
  xCommunicationsOurPosition: 'Unsere Position zu Cybermobbing',
  premiumTools: 'Premium Tools',
  premiumToolsSayHolaLiveChatPlugin: 'sayHola Live Chat Plugin',
  premiumToolsSayHolaBlogPlugin: 'sayHola Blog Plugin',
  premiumToolsTogetherSayHola: 'zusammen von sayHola',
  premiumToolsLiveLeagueApp: 'Live League App',
  programs: 'Programme',
  programsAdvertiseHola: 'Werben Sie mit Hola',
  programsFreelancerProgram: 'Freiberuflerprogramm',
  programsAffiliateRewardsProgram: 'Affiliate-Prämienprogramm',
  terms: 'Bedingungen',
  termsAdsPolicy: 'Anzeigenrichtlinie',
  termsPrivacyPolicy: 'Datenschutz-Bestimmungen',
  termsCommunityStandards: 'Gemeinschaftsstandards',
  termsTermsUse: 'Nutzungsbedingungen',
  termsLoyaltyRewardsTerms: 'Bedingungen für Treueprämien',
  // Home Page
  homeSlider: {
    haveAccount: 'Sie haben bereits ein Konto?',
    changeNumber: 'Müssen Sie Ihre Nummer ändern?',
    getStartedButton: 'Jetzt loslegen!',
    sliderPrevButton: 'Zurück',
    sliderNextButton: 'Weiterlesen',
    slogan1: 'Die Phot-Bridge-Technologie-Plattform von Hola Cross Platform hilft, Ihre Nutzer in langfristige Kunden umzuwandeln. Mit ChatBot Bridge können Sie Clients von einer Chat-App (WhatsApp, Telegramm usw.) generieren und alle unter einem Dashboard verwalten.',
    slogan2: 'Unsere technologischen Lösungen umfassen "einfach zum Erstellen von Vorlagen" für alle Servicetypen, von \'Food Orders\' auf \'Customer Support\' mithilfe von Funktionen, die Sie auswählen und verwenden.',
    slogan3: 'Sie können Ihre Vorlagen mit den Button-basierten Chatbots erstellen, um Kundengespräche zu erstellen. Indem die Art von Fragen erwartet werden, dass Kunden wahrscheinlich fragen, ist das ChatBot so programmiert, dass er angemessen reagiert, wodurch der Kunden benutzerfreundlich und authentisch erleben kann.',
    slogan4: 'Sehen Sie sich unsere Demo an oder beginnen Sie noch heute, indem Sie sich anmelden und Premium-Kunde werden',
    needMoreInfo: 'Benötigen Sie weitere Informationen?',
    learnMore: 'Lern mehr'
  },
  signIn: 'Einloggen',
  recovery: 'Wiederherstellung',
  otp_unconfirmed: 'Nicht bestätigt, versuche es noch einmal',
  otpDescription: 'Ihnen wurde ein SMS-Code zugesandt. Bitte Code unten eingeben.',
  otpEnterYourOtp: 'Geben Sie Ihren otp-Code ein',
  otpEnterYourPin: 'Geben Sie Ihren PIN-Code ein',
  verify_otp: 'Bestätigen',
  otpDontReceiveCode: 'Code nicht erhalten?',
  otpRequestSMSAgain: 'SMS erneut anfordern',
  otpRequestNewCode: 'Fordern Sie einen neuen Code an in',
  copyright: 'xKommunikation. Alle Rechte vorbehalten',
  enterPhoneNumber: 'Bitte wählen Sie Ihr Land und geben Sie Ihre vollständige Telefonnummer ein.',
  login: 'Anmeldung',
  validationSelectFile: 'Foto auswählen und hochladen',
  validationSameAs: 'Passwörter stimmen nicht überein',
  validationMaxLengthField: 'Maximale Anzahl Feldzeichen - :count',
  validationMinLengthField: 'Mindestanzahl von Feldzeichen - :count',
  validationRequired: 'Füllen Sie dieses Feld aus',
  validationEmail: 'Geben Sie eine gültige E-Mail ein',
  validationUrl: 'Geben Sie eine gültige URL ein',
  needChangePhone: 'Müssen Sie Ihre Nummer ändern?',
  needMoreInfo: 'Benötigen Sie weitere Informationen?',
  learnMore: 'Mehr erfahren',
  startUsingHola: 'Beginnen Sie mit sayHola, um den <br> Social Space anzuzeigen',
  startUsingHolaFollow: 'Beginnen Sie, sayHola zu verwenden, um zu folgen',
  socialSpace: 'sozialer Raum',
  socialSpaceInvite: 'Social Space-Einladung',
  socialSpaceInviteMessage: 'ein sicherer, privater Raum für Unternehmen, Prominente, Blogger, Influencer, Community und Einzelpersonen ohne Kommentare und Fake News',
  enterPhone: 'Telefon eingeben',
  payNow: 'Zahlen Sie jetzt',
  formCardName: 'Name des Karteninhabers',
  formCardNumber: 'Kartennummer',
  formExpDate: 'Verfallsdatum',
  formCvv: 'CVV',
  errorEmptyField: 'Dieses Feld wird benötigt',
  paymentBtnPay: 'Zahlen Sie jetzt',
  errorTitle: 'Fehler',
  error: 'Entschuldigung, etwas ist schief gelaufen',
  errorEmailInvalid: 'Geben sie eine gültige E-Mail-Adresse an',
  errorPhoneInvalid: 'Ungültige Telefonnummer',
  errorStripe: 'Bitte stellen Sie sicher, dass Sie alle Felder korrekt ausfüllen',
  offer: 'Bieten',
  enterCode: 'Code eingeben',
  enterPin: 'Pin eingeben',
  enterCardholderName: 'Geben Sie den Namen des Karteninhabers ein',
  logout: 'Ausloggen',
  confirmation: 'Bestätigung',
  thanksJoining: 'Vielen Dank für den Beitritt',
  successText: 'Besuchen Sie die FAQ und erfahren Sie, wie Sie die vielen Sonderfunktionen nutzen. Wenden Sie sich bei weiteren Fragen an den Support. Was würdest du gern tun?',
  downLoadApp: 'Lade App herunter',
  returnToHola: 'Rückkehr nach Hola',
  addSponsor: 'Sponsor hinzufügen',
  videoAndFAQ: 'Videos & FAQs',
  pin_unconfirmed: 'Geben Sie den richtigen Pin ein!',
  selectPackage: 'Paket auswählen',
  offerExpired: 'Sonderangebot abgelaufen!',
  jobBrief: 'Stellenbeschreibung',
  responsibilities: 'Verantwortlichkeiten',
  requirements: 'Anforderungen',
  currentJobPositions: 'Aktuelle Stellenangebote',
  sendCampaign: 'Kampagne senden',
  footerPrivateAppJobsPage: 'Arbeitsmöglichkeiten',
  enterLatinLetter: 'Dieses Feld darf nur alphanumerische Zeichen, Bindestriche und Unterstriche enthalten [a-z0-9-_]',
  nicknameExist: 'Nutzername vergeben!',
  successCreateProfile: 'Profil erfolgreich erstellt!',
  downloadApp: 'Laden Sie die App herunter',
  faqAndVideo: 'FAQ und Video',
  congratulations: 'Herzliche Glückwünsche',
  hosSubmitMessage: 'So reichen Sie Ihre Bewerbung ein:',
  profilePicture: 'Profilbild',
  profilePictureMessage: 'Fügen Sie Ihr Social Space-Profilbild hinzu, wir möchten wissen, mit wem wir chatten.',
  chatProfilePictureMessage: 'Fügen Sie Ihr Profilbild hinzu, wir möchten wissen, mit wem wir chatten.',
  firstName: 'Vorname',
  lastName: 'Nachname',
  nickname: 'Spitzname',
  location: 'Standort (Stadt, Land)',
  btnNext: 'Nächste',
  btnFinish: 'Beenden',
  email: 'Email',
  emailMessage: 'Ihre E-Mail wird nur zur Wiederherstellung verwendet. Durch die Eingabe Ihrer E-Mail akzeptieren Sie die Datenschutzerklärung und Geschäftsbedingungen von sayHola. Links werden zur Wiederherstellung an Ihre E-Mail gesendet.',
  changesSaved: 'Änderungen gespeichert!',
  emptyProfilePictureField: 'Bitte lade dein Profilbild hoch',
  register: 'Registrieren',
  resendOtpMessage: 'Eine weitere SMS wurde gesendet.',
  loginByQRCodeMessage: 'QR-Code zur Anmeldung per Smartphone',
  authOption: 'Bitte wählen Sie eine Option zum Anmelden.',
  newRegistration: 'Neue Registrierung',
  registrationOption: 'Die Registrierung erfordert eine E-Mail-Adresse und einen Benutzernamen, um die Dienste nutzen zu können. Sobald Sie angemeldet sind, haben Sie die Möglichkeit, mit Ihrer Telefonnummer mit anderen Messenger-Apps zu synchronisieren',
  loginWithApp: 'anmelden mit app',
  loginWithAppOption: 'Öffnen Sie Hola auf Ihrem Telefon und gehen Sie zum Menü in der oberen rechten Ecke der App. Klicken Sie auf das QR-Code-Symbol. Richten Sie Ihr Telefon auf diesen Bildschirm, um die Anmeldung zu bestätigen.',
  recoveryOption: 'Erholung',
  recoveryOptionText: 'Zur Wiederherstellung Geben Sie Ihre registrierte E-Mail-Adresse ein, um sich über Hot-Link anzumelden. Erfahren Sie mehr, indem Sie den FAQ-Bereich besuchen',
  enterEmailRecovery: 'Geben Sie Ihre registrierte E-Mail-Adresse zur Wiederherstellung ein:',
  startUsingSayHola: 'Beginnen Sie mit der Nutzung von sayHola',
  formEmail: 'Deine E-Mail',
  notFoundUserLabel: 'Benutzer mit dieser E-Mail nicht gefunden',
  successSendRecoveryEmailMessage: 'Überprüfen Sie Ihre E-Mails und folgen Sie den Anweisungen!',
  chatBots: 'ChatBots',
  BdtlAgencyProgram: 'BDTL-Agenturprogramm',
  agencyTerms: 'Bedingungen der Agentur',
  holaExtensions: 'Hola-Erweiterung',
  holaBlog: 'Hola Blog',
  authSlogan1: 'Despaxo Wir rekrutieren jetzt Lieferfahrer für Lebensmittel und Produkte in Lateinamerika',
  authSlogan2: 'Wir zahlen die höchsten Fahrtpreise: $2 pro Fahrt plus extra pro KM',
  authSlogan3: 'Despaxo nimmt keine Provisionen.',
  authSlogan4: 'Seien Sie Ihr eigener Chef Lassen Sie sich superschnell bezahlen Melden Sie sich noch heute an.',
  loginByEmail: 'Anmeldung per E-Mail',
  registerByEmail: 'Registrieren Sie sich per E-Mail',
  registerToday: 'Melde dich noch heute an.',
  startUsingSayHolaTo: 'Start Using sayHola to',
  myInfo: 'MeineInfo',
  authTitleSlogan: 'Ich habe Sie gebeten, meine Dropcard zu scannen, damit wir uns verständigen können. Bitte nutzen Sie eine der verfügbaren Optionen oder registrieren Sie sich und melden Sie sich bei sayHola an, um mit mir zu chatten.',
  shareContact: 'Kontakt teilen',
  addToContacts: 'Zu den Kontakten hinzufügen',
  startChatting: 'Fangen Sie an zu chatten',
  followSocial: 'Folgen Sie Social',
  communicateWith: 'Kommunizieren mit',
  linkCopied: 'Link in die Zwischenablage kopiert!',
  permissionMessage: 'Zur Sicherheit dieses Benutzers haben Sie keinen Zugriff auf diesen QR-Code oder Link – fragen Sie den Benutzer nach einem neuen QR-Code\\Link',
  expiredMessage: 'Dieser QR-Code\\Link ist abgelaufen - fragen Sie den Benutzer persönlich nach einem neuen QR-Code\\Link'
}
