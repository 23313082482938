import Vue from 'vue'
import Router from 'vue-router'

// modules
import Auth from '@/modules/auth/index.vue'

Vue.use(Router)

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:nickname?',
      name: 'auth',
      component: Auth
    },
    {
      path: '/*',
      redirect: {
        name: 'auth'
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.path !== from.path) {
      return { x: 0, y: 0 }
    }
  }
})

export default router
