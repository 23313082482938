import Vue from 'vue'
import VModal from 'vue-js-modal'
import Vuelidate from 'vuelidate'
import Toasted from 'vue-toasted'

Vue.use(VModal)
Vue.use(Vuelidate)
Vue.use(Toasted, {
  duration: 7000,
  position: 'bottom-right'
})
