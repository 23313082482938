export default {
  // footer
  termOfService: 'Срок оказания услуг',
  privacyPolicy: 'Политика конфиденциальности',
  xCommunications: 'xСвязь',
  xCommunicationsInvestorRelations: 'Связи с инвесторами',
  xCommunicationsMediaRequest: 'Медиа-запрос',
  xCommunicationsOurPositionPrivacy: 'Наша позиция в отношении конфиденциальности',
  xCommunicationsKnowledgeBase: 'База знаний',
  xCommunicationsOurPosition: 'Наша позиция в отношении киберзапугивания',
  premiumTools: 'Премиум Инструменты',
  premiumToolsSayHolaLiveChatPlugin: 'Плагин sayHola Live Chat',
  premiumToolsSayHolaBlogPlugin: 'Плагин sayHola Blog',
  premiumToolsTogetherSayHola: 'вместе SayHola',
  premiumToolsLiveLeagueApp: 'Приложение Live League',
  programs: 'Программы',
  programsAdvertiseHola: 'Рекламируйте с Hola',
  programsFreelancerProgram: 'Программа для фрилансеров',
  programsAffiliateRewardsProgram: 'Партнерская программа вознаграждений',
  terms: 'Условия',
  termsAdsPolicy: 'Политика в отношении рекламы',
  termsPrivacyPolicy: 'Политика конфиденциальности',
  termsCommunityStandards: 'Стандарты Сообщества',
  termsTermsUse: 'Условия эксплуатации',
  termsLoyaltyRewardsTerms: 'Условия вознаграждения за лояльность',
  // Home Page
  homeSlider: {
    haveAccount: 'Уже есть аккаунт?',
    changeNumber: 'Вам нужно изменить свой номер?',
    getStartedButton: 'Начать сейчас!',
    sliderPrevButton: 'Назад',
    sliderNextButton: 'Продолжить читать',
    slogan1: 'Технологическая платформа Hola Cross Platform Chatbot Bridge помогает превратить ваших пользователей в постоянных клиентов. С помощью Chatbot Bridge вы можете генерировать клиентов из любого приложения для чата (WhatsApp, Telegram и т. д.) и управлять ими всеми с помощью одной панели управления.',
    slogan2: 'Наши технологические решения включают «простые в создании шаблоны» для всех типов услуг, от «заказов еды» до «поддержки клиентов», используя только те функции, которые вы выбираете и используете.',
    slogan3: 'Вы можете создавать свои шаблоны с помощью чат-ботов на основе кнопок, чтобы отображать разговоры с клиентами. Предвидя виды вопросов, которые могут задать клиенты, чат-бот запрограммирован на соответствующий ответ, тем самым делая взаимодействие с клиентом удобным и аутентичным.',
    slogan4: 'Посмотрите нашу демонстрацию или начните работу сегодня, войдя в систему и став клиентом Premium.',
    needMoreInfo: 'Нужна дополнительная информация?',
    learnMore: 'Посмотреть больше'
  },
  signIn: 'Войти',
  recovery: 'Восстановление',
  otp_unconfirmed: 'Отп не подтвержден, попробуйте еще раз',
  otpDescription: 'Вам отправлен sms-код. Пожалуйста, введите код ниже.',
  otpEnterYourOtp: 'Введите ваш код OTP',
  otpEnterYourPin: 'Введите свой пин-код',
  verify_otp: 'Подтверждать',
  otpDontReceiveCode: 'Не получили код?',
  otpRequestSMSAgain: 'Запросить СМС еще раз',
  otpRequestNewCode: 'Запросить новый код в',
  copyright: 'xСвязь. Все права защищены',
  enterPhoneNumber: 'Пожалуйста, выберите свою страну и введите свой полный номер телефона.',
  login: 'Авторизоваться',
  validationSelectFile: 'Выбрать и загрузить фото',
  validationSameAs: 'Пароли не соответствуют',
  validationMaxLengthField: 'Максимальное количество символов поля - :count',
  validationMinLengthField: 'Минимальное количество символов поля - :count',
  validationRequired: 'Заполните это поле',
  validationEmail: 'Введите действующий адрес электронной почты',
  validationUrl: 'Введите действительный URL',
  needChangePhone: 'Вам нужно изменить свой номер?',
  needMoreInfo: 'Нужна дополнительная информация?',
  learnMore: 'Узнать больше',
  startUsingHola: 'Начните использовать sayHola для просмотра <br> Социального пространства',
  startUsingHolaFollow: 'Начните использовать sayHola, чтобы подписаться',
  socialSpace: 'социальное пространство',
  socialSpaceInvite: 'Приглашение в социальное пространство',
  socialSpaceInviteMessage: 'безопасное и безопасное личное пространство для бизнеса, знаменитостей, блоггеров, влиятельных лиц, сообщества и отдельных лиц без комментариев и фейковых новостей',
  enterPhone: 'Введите телефон',
  payNow: 'Купить сейчас',
  formCardName: 'Имя владельца карты',
  formCardNumber: 'Номер карты',
  formExpDate: 'Срок годности',
  formCvv: 'CVV',
  errorEmptyField: 'Это поле обязательно к заполнению',
  paymentBtnPay: 'Купить сейчас',
  errorTitle: 'Ошибка',
  error: 'Извините, что-то пошло не так',
  errorEmailInvalid: 'Введите действительный адрес электронной почты',
  errorPhoneInvalid: 'Неправильный номер телефона',
  errorStripe: 'Убедитесь, что вы правильно заполнили все поля',
  offer: 'Предложение',
  enterCode: 'Введите код',
  enterPin: 'Введите пин-код',
  enterCardholderName: 'Введите имя держателя карты',
  logout: 'Выйти',
  confirmation: 'Подтверждение',
  thanksJoining: 'Спасибо, что присоединились',
  successText: 'Посетите FAQ и узнайте, как использовать множество специальных функций. Обратитесь в службу поддержки, если у вас возникнут дополнительные вопросы. Чем бы Вы хотели заняться?',
  downLoadApp: 'Скачать приложение',
  returnToHola: 'Вернуться в Hola',
  addSponsor: 'Добавить спонсора',
  videoAndFAQ: 'Видео и FAQ',
  pin_unconfirmed: 'Введите правильный пин-код!',
  selectPackage: 'Выбрать пакет',
  offerExpired: 'Срок действия специального предложения истек!',
  jobBrief: 'Краткое описание работы',
  responsibilities: 'Обязанности',
  requirements: 'Требования',
  currentJobPositions: 'Текущие возможности трудоустройства',
  sendCampaign: 'Отправить кампанию',
  footerPrivateAppJobsPage: 'Возможности работы',
  enterLatinLetter: 'Это поле может содержать только буквенно-цифровые символы, тире и подчеркивание [a-z0-9-_]',
  nicknameExist: 'Псевдоним уже существует!',
  successCreateProfile: 'Профиль успешно создан!',
  downloadApp: 'скачать приложение',
  faqAndVideo: 'часто задаваемые вопросы и видео',
  congratulations: 'Поздравления',
  hosSubmitMessage: 'Как подать заявку:',
  profilePicture: 'Изображение профиля',
  profilePictureMessage: 'Добавьте изображение своего профиля в Social Space, мы хотим знать, с кем мы общаемся.',
  chatProfilePictureMessage: 'Добавьте изображение своего профиля, мы хотим знать, с кем мы общаемся.',
  firstName: 'Имя',
  lastName: 'Фамилия',
  nickname: 'Ник',
  location: 'Местоположение (город, страна)',
  btnNext: 'Далее',
  btnFinish: 'Далее',
  email: 'Эл. адрес',
  emailMessage: 'Ваш адрес электронной почты будет использован только для восстановления. Введя свой адрес электронной почты, вы соглашаетесь с политикой конфиденциальности и условиями sayHola. Ссылки будут отправлены на вашу электронную почту для восстановления.',
  changesSaved: 'Изменения сохранены!',
  emptyProfilePictureField: 'Пожалуйста, загрузите изображение вашего профиля',
  register: 'Регистрация ',
  resendOtpMessage: 'Было отправлено еще одно SMS.',
  loginByQRCodeMessage: 'QR-код для входа через смартфон',
  authOption: 'Пожалуйста, выберите вариант для входа.',
  newRegistration: 'новая регистрация',
  registrationOption: 'Для регистрации требуется адрес электронной почты и имя пользователя для использования услуг. После входа в систему у вас есть возможность синхронизации с другими приложениями для обмена сообщениями, используя ваш номер телефона.',
  loginWithApp: 'войти через приложение',
  loginWithAppOption: 'Откройте Hola на своем телефоне, перейдите в меню, расположенное в правом верхнем углу приложения. Щелкните значок QR-кода. Наведите телефон на этот экран, чтобы подтвердить вход.',
  recoveryOption: 'восстановление',
  recoveryOptionText: 'Для восстановления Введите свой зарегистрированный адрес электронной почты, чтобы войти с помощью горячей ссылки. Узнайте больше, посетив раздел часто задаваемых вопросов',
  enterEmailRecovery: 'Введите зарегистрированный адрес электронной почты для восстановления:',
  startUsingSayHola: 'Начать использовать sayHola',
  formEmail: 'Ваш адрес электронной почты',
  notFoundUserLabel: 'Пользователь с таким адресом электронной почты не найден',
  successSendRecoveryEmailMessage: 'Проверьте почту и следуйте инструкциям!',
  chatBots: 'Чат-боты',
  BdtlAgencyProgram: 'Агентская программа БДТЛ',
  agencyTerms: 'Условия агентства',
  holaExtensions: 'Хола Расширение',
  holaBlog: 'Hola Блог',
  authSlogan1: 'Despaxo Сейчас мы набираем водителей для доставки еды и продуктов в Латинской Америке.',
  authSlogan2: 'Мы платим самые высокие тарифы на поездки: 2 доллара за поездку плюс доплата за километр.',
  authSlogan3: 'Despaxo не берет комиссий.',
  authSlogan4: 'Будьте сами себе начальником Получайте сверхбыстрые деньги Зарегистрируйтесь сегодня.',
  loginByEmail: 'Войти по электронной почте',
  registerByEmail: 'Зарегистрируйтесь по электронной почте',
  registerToday: 'Зарегистрируйтесь сегодня.',
  startUsingSayHolaTo: 'Начните использовать sayHola, чтобы',
  myInfo: 'Мояинфо',
  authTitleSlogan: 'Я попросил вас отсканировать мою открытку, чтобы мы могли общаться. Пожалуйста, используйте один из доступных вариантов или зарегистрируйтесь и войдите в систему sayHola, чтобы начать общаться со мной.',
  shareContact: 'Поделиться контактом',
  addToContacts: 'Добавить в контакты',
  startChatting: 'Начать чат',
  followSocial: 'Следите за социальными сетями',
  communicateWith: 'Общаться с',
  linkCopied: 'Ссылка скопирована в буфер обмена!',
  permissionMessage: 'Para a segurança deste usuário, você não tem acesso a este qr-code ou link - peça ao usuário um novo qr-code\\link',
  expiredMessage: 'Este Qr-code\\link expirou - peça ao usuário pessoalmente um novo qr-code\\link'
}
