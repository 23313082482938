export default {
  // footer
  xCommunications: 'xCommunications',
  xCommunicationsInvestorRelations: 'Investor Relations',
  xCommunicationsMediaRequest: 'Media Request',
  xCommunicationsOurPositionPrivacy: 'Our Position on Privacy',
  xCommunicationsKnowledgeBase: 'Knowledge Base',
  xCommunicationsOurPosition: 'Our Position on Cyberbullying',
  premiumTools: 'Premium Tools',
  premiumToolsSayHolaLiveChatPlugin: 'sayHola Live Chat plugin',
  premiumToolsSayHolaBlogPlugin: 'sayHola Blog plugin',
  premiumToolsTogetherSayHola: 'together by sayHola',
  premiumToolsLiveLeagueApp: 'Live League App',
  programs: 'Programs',
  programsAdvertiseHola: 'Advertise with Hola',
  programsFreelancerProgram: 'Freelancer Program',
  programsAffiliateRewardsProgram: 'Affiliate Rewards Program',
  terms: 'Terms',
  termsAdsPolicy: 'Ads Policy',
  termsPrivacyPolicy: 'Privacy Policy',
  termsCommunityStandards: 'Community Standards',
  termsTermsUse: 'Terms of Use',
  termsLoyaltyRewardsTerms: 'Loyalty Rewards Terms',
  termOfService: 'Term of Services',
  privacyPolicy: 'Privacy Policy',
  // Home Page
  homeSlider: {
    haveAccount: 'Already have an account?',
    changeNumber: 'Need to change your number?',
    getStartedButton: 'Get started now!',
    sliderPrevButton: 'Back',
    sliderNextButton: 'Continue to read',
    slogan1: 'Hola Cross Platform Chatbot Bridge technology platform helps convert your users into long term customers. With Chatbot Bridge, you can generate clients from any chat app (WhatsApp, Telegram etc)  and manage them all under one dashboard.',
    slogan2: 'Our Technological Solutions include ‘easy to build templates’ for all service types, from ‘food orders’ to ‘customer support’ using only features you select and use. ',
    slogan3: 'You can build your templates using Button-based Chatbots to map out customer conversations. By anticipating the kinds of questions that customers are likely to ask, the chatbot is programmed to respond appropriately, thereby making the customer experience user friendly and authentic.',
    slogan4: 'View our demo or get started today by logging in and becoming a Premium customer',
    needMoreInfo: 'Need more info?',
    learnMore: 'Learn More'
  },
  signIn: 'Sign in',
  recovery: 'Recovery',
  otp_unconfirmed: 'Otp unconfirmed, try again',
  otpDescription: 'An sms code has been sent to you. Please enter code below.',
  otpEnterYourOtp: 'Enter your otp code',
  otpEnterYourPin: 'Enter your pin code',
  verify_otp: 'Confirm',
  otpDontReceiveCode: "Didn't receive the code?",
  otpRequestSMSAgain: 'Request SMS again',
  otpRequestNewCode: 'Request a new code in',
  copyright: 'xCommunications. All rights reserved',
  enterPhoneNumber: 'Please choose your country and enter your full phone number.',
  login: 'Login',
  validationSelectFile: 'Select and upload photo',
  validationSameAs: 'Passwords do not match',
  validationMaxLengthField: 'Maximum number of field characters - :count',
  validationMinLengthField: 'Minimum number of field characters - :count',
  validationRequired: 'Fill out this field',
  validationEmail: 'Enter a valid email',
  validationUrl: 'Enter a valid url',
  needChangePhone: 'Need to change your number?',
  learnMore: 'Learn more',
  startUsingHola: 'Start using sayHola to view the <br> Social Space',
  startUsingHolaFollow: 'Start using sayHola to Follow',
  socialSpace: 'social space',
  socialSpaceInvite: 'Social Space invite',
  socialSpaceInviteMessage: 'a safe and secure, private space for business, celebrities, bloggers, influencers, community, and individuals without comments and fake news',
  enterPhone: 'Enter phone',
  payNow: 'Pay Now',
  formCardName: 'Cardholder name',
  formCardNumber: 'Card number',
  formExpDate: 'Expiry date',
  formCvv: 'CVV',
  errorEmptyField: 'This field is required',
  paymentBtnPay: 'Pay now',
  errorTitle: 'Error',
  error: 'Sorry, something went wrong',
  errorEmailInvalid: 'Enter a valid email address',
  errorPhoneInvalid: 'Invalid phone number',
  errorStripe: 'Please make sure that you fill in all the fields correctly',
  offer: 'Offer',
  enterCode: 'Enter code',
  enterPin: 'Enter pin',
  enterCardholderName: 'Enter cardholder name',
  logout: 'Logout',
  confirmation: 'Confirmation',
  thanksJoining: 'Thank you for joining',
  successText: 'Visit FAQ and learn how to use the many special features. Contact support for additional questions. What would you like to do?',
  downLoadApp: 'Download App',
  returnToHola: 'Return to Hola',
  addSponsor: 'Add Sponsor',
  videoAndFAQ: 'Videos & FAQ\'s',
  pin_unconfirmed: 'Enter the correct pin!',
  selectPackage: 'Select package',
  offerExpired: 'Special offer expired!',
  jobBrief: 'Job brief',
  responsibilities: 'Responsibilities',
  requirements: 'Requirements',
  currentJobPositions: 'Current Job Opportunities',
  sendCampaign: 'Send Campaign',
  footerPrivateAppJobsPage: 'Work Opportunities',
  enterLatinLetter: 'This field can only contain alphanumeric characters, dash and underscore [a-z0-9-_]',
  nicknameExist: 'Nickname already exists!',
  successCreateProfile: 'Profile created successfully!',
  downloadApp: 'download the app',
  faqAndVideo: 'faq and video',
  congratulations: 'Congratulations',
  hosSubmitMessage: 'How to submit your application:',
  profilePicture: 'Profile Picture',
  profilePictureMessage: 'Add your Social Space profile picture, we want to know who we are chatting with.',
  chatProfilePictureMessage: 'Add your profile picture, we want to know who we are chatting with.',
  firstName: 'First Name',
  lastName: 'Last Name',
  nickname: 'Nickname',
  location: 'Location (city, country)',
  btnNext: 'Next',
  btnFinish: 'Finish',
  email: 'Email',
  emailMessage: 'Your email will be used for recovery only. By entering your email you accept sayHola privacy policy and terms and conditions. Links will be sent to your email for recovery.',
  changesSaved: 'Changes saved!',
  emptyProfilePictureField: 'Please upload your profile picture',
  register: 'Register',
  resendOtpMessage: 'One more SMS was sent.',
  loginByQRCodeMessage: 'QR code for login via smartphone',
  newRegistration: 'new registration',
  registrationOption: 'Registration requires an email and username to use the services. Once logged-in you have the choice to sync with other messenger Apps using your phone number',
  loginWithApp: 'login with app',
  loginWithAppOption: 'Open Hola on your phone, go to the menu located in top right hand corner of the App. Click the QR code icon. Point your phone at this screen to confirm login.',
  recoveryOption: 'recovery',
  recoveryOptionText: 'For Recovery Enter your registered email to login using Hot-link. Learn more by visiting FAQ section',
  enterEmailRecovery: 'Enter your registered email to recovery:',
  authOption: 'Please select an option to Login',
  startUsingSayHola: 'Start Using sayHola',
  formEmail: 'Your email',
  notFoundUserLabel: 'User with this email not found',
  successSendRecoveryEmailMessage: 'Check your email and follow the instructions!',
  chatBots: 'ChatBots',
  BdtlAgencyProgram: 'BDTL Agency Program',
  agencyTerms: 'Agency Terms',
  holaExtensions: 'Hola Extension',
  holaBlog: 'Hola Blog',
  authSlogan1: 'Despaxo We are now recruiting Delivery Drivers for Food and products in Latin America',
  authSlogan2: 'We pay the highest trip rates: $2 per trip plus extra per KM',
  authSlogan3: 'Despaxo takes No Commissions.',
  authSlogan4: 'Be your own boss Get paid super-fast Register today.',
  loginByEmail: 'Login by email',
  registerByEmail: 'Register by email',
  registerToday: 'Register Today.',
  startUsingSayHolaTo: 'Start Using sayHola to',
  myInfo: 'myinfo',
  authTitleSlogan: 'I asked you to scan my dropcard so that we can communicate. Please use one of the options available or register and login to sayHola to start chatting with me.',
  shareContact: 'Share Contact',
  addToContacts: 'Add to Contacts',
  startChatting: 'Start Chatting',
  followSocial: 'Follow Social',
  communicateWith: 'Communicate with',
  linkCopied: 'Link Copied to clipboard!',
  permissionMessage: 'For the security of this user, you do not have access to this qr-code or link - ask the user for a new qr-code\\link',
  expiredMessage: 'This Qr-code\\link expired - ask the user personally for a new qr-code\\link'
}
