export default {
  xCommunications: 'xCommunications',
  xCommunicationsInvestorRelations: 'Relaciones con inversionistas',
  xCommunicationsMediaRequest: 'Solicitud de Medios',
  xCommunicationsOurPositionPrivacy: 'Nuestra posición en Privacidad',
  xCommunicationsKnowledgeBase: 'Base de conocimientos',
  xCommunicationsOurPosition: 'Nuestra Posición en Ciberacoso',
  premiumTools: 'Herramientas Premium',
  premiumToolsSayHolaLiveChatPlugin: 'sayHola Live Chat plugin',
  premiumToolsSayHolaBlogPlugin: 'sayHola Blog plugin',
  premiumToolsTogetherSayHola: 'together por sayHola',
  premiumToolsLiveLeagueApp: 'Live League App',
  programs: 'Programas',
  programsAdvertiseHola: 'Anunciece con Hola',
  programsFreelancerProgram: 'Programa Freelancer',
  programsAffiliateRewardsProgram: 'Programa de recompensas para afiliados',
  terms: 'Términos',
  termsAdsPolicy: 'Política de Anuncios',
  termsPrivacyPolicy: 'Política de Privacidad',
  termsCommunityStandards: 'Estándares de la Comunidad',
  termsTermsUse: 'Términos de Uso',
  termsLoyaltyRewardsTerms: 'Términos de las recompensas por fidelidad',
  termOfService: 'Términos de Servicios',
  privacyPolicy: 'Política de Privacidad',
  // home slider
  homeSlider: {
    haveAccount: 'Ya tienes una cuenta?',
    changeNumber: 'Necesitas cambiar tu número?',
    getStartedButton: 'Empieza ahora!',
    sliderPrevButton: 'Atras',
    sliderNextButton: 'Continuar leyendo',
    slogan1: 'La plataforma de tecnología Hola Cross Platform Chatbot Bridge ayuda a convertir a sus usuarios en clientes a largo plazo. Con Chatbot Bridge, puede generar clientes desde cualquier aplicación de chat (WhatsApp, Telegram, etc.) y administrarlos todos en un solo tablero.',
    slogan2: 'Nuestras soluciones tecnológicas incluyen "plantillas fáciles de crear" para todo tipo de servicios, desde "pedidos de comida" hasta "atención al cliente" utilizando solo las funciones que usted seleccione y use.',
    slogan3: 'Puede crear sus plantillas utilizando chatbots basados en botones para trazar conversaciones con los clientes. Al anticipar el tipo de preguntas que es probable que hagan los clientes, el chatbot está programado para responder adecuadamente, lo que hace que la experiencia del cliente sea fácil de usar y auténtica.',
    slogan4: 'Vea nuestra demostración o comience hoy iniciando sesión y convirtiéndose en un cliente Premium',
    needMoreInfo: '¿Necesitas más información?',
    learnMore: 'Aprende más'
  },
  signIn: 'Ingresar',
  recovery: 'Recuperar',
  otp_unconfirmed: 'Otp no confirmado, vuelve a intentarlo',
  otpDescription: 'Se le ha enviado un código sms. Ingrese el código a continuación.',
  otpEnterYourOtp: 'Ingrese su código otp',
  otpEnterYourPin: 'Ingrese su código pin',
  verify_otp: 'Confirmar',
  otpDontReceiveCode: '¿No recibiste el código?',
  otpRequestSMSAgain: 'Solicitar SMS de nuevo',
  otpRequestNewCode: 'Solicite un nuevo código en',
  copyright: 'xComunicaciones. Reservados todos los derechos',
  enterPhoneNumber: 'Elija su país e ingrese su número de teléfono completo.',
  login: 'Acceso',
  validationSelectFile: 'Seleccionar y subir foto',
  validationSameAs: 'Las contraseñas no coinciden',
  validationMaxLengthField: 'Número máximo de caracteres de campo - :count',
  validationMinLengthField: 'Número mínimo de caracteres de campo - :count',
  validationRequired: 'Complete este campo',
  validationEmail: 'Introduzca un correo electrónico válido',
  validationUrl: 'Ingrese una URL válida',
  needChangePhone: '¿Necesitas cambiar tu número?',
  needMoreInfo: '¿Necesitas más información?',
  learnMore: 'Aprende más',
  startUsingHola: 'Empiece a utilizar sayHola para ver el espacio social <br>',
  startUsingHolaFollow: 'Comience a usar sayHola para seguir',
  socialSpace: 'espacio social',
  socialSpaceInvite: 'Invitación al espacio social',
  socialSpaceInviteMessage: 'un espacio privado seguro para empresas, celebridades, blogueros, personas influyentes, comunidad e individuos sin comentarios ni noticias falsas',
  enterPhone: 'Ingrese el teléfono',
  payNow: 'Pagar ahora',
  formCardName: 'Nombre del titular de la tarjeta',
  formCardNumber: 'Número de tarjeta',
  formExpDate: 'Fecha de caducidad',
  formCvv: 'CVV',
  errorEmptyField: 'Este campo es obligatorio',
  paymentBtnPay: 'Pagar ahora',
  errorTitle: 'Error',
  error: 'Perdón, algo salió mal',
  errorEmailInvalid: 'Introduzca una dirección de correo electrónico válida',
  errorPhoneInvalid: 'Numero de telefono invalido',
  errorStripe: 'Por favor, asegúrese de completar todos los campos correctamente.',
  offer: 'Oferta',
  enterCode: 'Introduzca el código',
  enterPin: 'Ingrese su PIN',
  enterCardholderName: 'Ingrese el nombre del titular de la tarjeta',
  logout: 'Cerrar sesión',
  confirmation: 'Confirmación',
  thanksJoining: 'Gracias por unirte',
  successText: 'Visite Preguntas frecuentes y aprenda a utilizar las numerosas funciones especiales. Comuníquese con el soporte para preguntas adicionales. Que te gustaría hacer?',
  downLoadApp: 'Descargar aplicación',
  returnToHola: 'Regresar a Hola',
  addSponsor: 'Agregar patrocinador',
  videoAndFAQ: 'Videos y preguntas frecuentes',
  pin_unconfirmed: 'Ingrese el pin correcto!',
  selectPackage: 'Seleccionar paquete',
  offerExpired: 'Oferta especial caducada.',
  jobBrief: 'Resumen de trabajo',
  responsibilities: 'Responsabilidades',
  requirements: 'Requisitos',
  currentJobPositions: 'Oportunidades laborales actuales',
  sendCampaign: 'Enviar campaña',
  footerPrivateAppJobsPage: 'Oportunidades de trabajo',
  enterLatinLetter: 'Este campo solo puede contener caracteres alfanuméricos, guiones y guiones bajos [a-z0-9-_]',
  nicknameExist: '¡El apodo ya existe!',
  successCreateProfile: '¡Perfil creado con éxito!',
  downloadApp: 'descarga la aplicación',
  faqAndVideo: 'faq y video',
  congratulations: 'Felicidades',
  hosSubmitMessage: 'Cómo enviar su solicitud:',
  profilePicture: 'Foto de perfil',
  profilePictureMessage: 'Agrega tu foto de perfil de Social Space, queremos saber con quién estamos chateando.',
  chatProfilePictureMessage: 'Agrega tu foto de perfil, queremos saber con quién estamos chateando.',
  firstName: 'Primer nombre',
  lastName: 'Apellido',
  nickname: 'Apodo',
  location: 'Ubicación (ciudad, país)',
  btnNext: 'Próximo',
  btnFinish: 'Terminar',
  email: 'Correo electrónico',
  emailMessage: 'Su correo electrónico se utilizará solo para la recuperación. Al ingresar su correo electrónico, acepta la política de privacidad y los términos y condiciones de sayHola. Se enviarán enlaces a su correo electrónico para su recuperación.',
  changesSaved: '¡Cambios guardados!',
  emptyProfilePictureField: 'Sube tu foto de perfil',
  register: 'Registrarse',
  resendOtpMessage: 'Se envió un SMS más.',
  loginByQRCodeMessage: 'Código QR para iniciar sesión a través de un teléfono inteligente',
  authOption: 'Seleccione una opción para iniciar sesión.',
  newRegistration: 'nuevo registro',
  registrationOption: 'El registro requiere un correo electrónico y un nombre de usuario para utilizar los servicios. Una vez que haya iniciado sesión, tiene la opción de sincronizar con otras aplicaciones de mensajería usando su número de teléfono',
  loginWithApp: 'iniciar sesión con la aplicación',
  loginWithAppOption: 'Abra Hola en su teléfono, vaya al menú ubicado en la esquina superior derecha de la aplicación. Haga clic en el icono del código QR. Apunte su teléfono a esta pantalla para confirmar el inicio de sesión.',
  recoveryOption: 'recuperación',
  recoveryOptionText: 'Para recuperación Ingrese su correo electrónico registrado para iniciar sesión usando Hot-link. Obtenga más información visitando la sección de preguntas frecuentes',
  enterEmailRecovery: 'Ingrese su correo electrónico registrado para la recuperación:',
  startUsingSayHola: 'Empezar a usar sayHola',
  formEmail: 'Tu correo electrónico',
  notFoundUserLabel: 'Usuario con este correo electrónico no encontrado',
  successSendRecoveryEmailMessage: '¡Revisa tu correo electrónico y sigue las instrucciones!',
  chatBots: 'ChatBots',
  BdtlAgencyProgram: 'Programa de Agencia BDTL',
  agencyTerms: 'Términos de la agencia',
  holaExtensions: 'Hola Extensión',
  holaBlog: 'Hola Blog',
  authSlogan1: 'Despaxo Estamos reclutando ahora conductores de entrega de alimentos y productos en América Latina',
  authSlogan2: 'Pagamos las tarifas de viaje más altas: $2 por viaje más extra por KM',
  authSlogan3: 'Despaxo no cobra comisiones.',
  authSlogan4: 'Sea su propio jefe Reciba un pago súper rápido Regístrese hoy.',
  loginByEmail: 'Iniciar sesión por correo electrónico',
  registerByEmail: 'Regístrese por correo electrónico',
  registerToday: 'Regístrese hoy.',
  startUsingSayHolaTo: 'Comience a usar sayHola para',
  myInfo: 'Miinfo',
  authTitleSlogan: 'Te pedí que escanees mi dropcard para que podamos comunicarnos. Utilice una de las opciones disponibles o regístrese e inicie sesión en sayHola para comenzar a chatear conmigo.',
  shareContact: 'Compartir contacto',
  addToContacts: 'Añadir contactos',
  startChatting: 'Empezar a chatear',
  followSocial: 'Seguir redes sociales',
  communicateWith: 'Comunicarse con',
  linkCopied: '¡Enlace copiado al portapapeles!',
  permissionMessage: 'Para la seguridad de este usuario, no tiene acceso a este código qr o enlace; solicite al usuario un nuevo código qr\\enlace',
  expiredMessage: 'Este código QR\\enlace caducó: solicite personalmente al usuario un nuevo código QR\\enlace'
}
