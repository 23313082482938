import Vue from 'vue'
import App from './App.vue'

// plugins&components
import './utils/plugins'
import './utils/global-components'
import './utils/translations'

// router&store
import router from './router'
import store from './store'
import VueRouter from 'vue-router'

Vue.config.productionTip = false
Vue.use(VueRouter)

const app = new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
})

Vue.initI18nManager().then(() => {
  app.$mount('#app')
})
