import api from '@/api'

const state = {
  isAuthenticated: false,
  isLoading: false,
  otpResponse: null,
  isLoadingConfirm: false,
  isLoadingLogin: false,
  socialInfo: null,
  socialInfoLoading: false,
  accessToken: null,
  refreshToken: null,

  authByCodeResponse: null,
  authByCodeLoading: false,

  user: null,
  userLoading: false,
  socialLoading: false,
  socialProfile: null
}

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  isLoading: (state) => state.isLoading,
  otpResponse: state => state.otpResponse,
  isLoadingConfirm: state => state.isLoadingConfirm,
  isLoadingLogin: state => state.isLoadingLogin,
  socialInfo: state => state.socialInfo,
  socialInfoLoading: state => state.socialInfoLoading,
  accessToken: state => state.accessToken,
  refreshToken: state => state.refreshToken,

  authByCodeResponse: state => state.authByCodeResponse,
  authByCodeLoading: state => state.authByCodeLoading,

  user: state => state.user,
  userLoading: state => state.userLoading,

  socialLoading: state => state.socialLoading,
  socialProfile: state => state.socialProfile
}

const actions = {
  async refresh ({ dispatch, commit, state }) {
    const handleError = () => {
      dispatch('logout')
      window.location = '/'
    }
    try {
      const { status, data } = await api.auth.refreshToken({
        refreshToken: state.refreshToken
      })
      if (status === 'error') {
        handleError()
        return Promise.reject(data)
      }
      commit('authSaveToken', data)
    } catch (e) {
      // console.log('CATCH refresh error', e.response && e.response.data)
      handleError()
      return e
    }
  },
  async authByCode ({ commit }, payload) {
    try {
      commit('changeAuthByCodeLoading', true)
      const response = await api.auth.authByCode(payload)
      if (response.data && Object.keys(response.data).length === 0 && Object.getPrototypeOf(response.data) === Object.prototype) {

      } else {
        commit('authSaveToken', response.data.token)
      }
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeAuthByCodeLoading', false)
    }
  },
  async sendQRCode ({ commit }, payload) {
    // eslint-disable-next-line no-useless-catch
    try {
      await api.auth.sendQRCode(payload)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    }
  },
  async register ({ state }, data) {
    const params = {
      ...data,
      device: {
        id: '73617948-6f6c-6120-426c-6f6720777000',
        type: 'web'
      }
    }
    await api.auth.register(params)
  },
  async getUser ({ commit }, nickname) {
    try {
      commit('changeUserLoading', true)
      const { data } = await api.auth.getUser(nickname)
      commit('saveUser', data)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeUserLoading', false)
    }
  },
  async getSocialProfile ({ commit }, nickname) {
    try {
      commit('changeSocialUserLoading', true)
      const { results } = await api.auth.getSocialProfile(nickname)
      commit('saveSocialProfile', results)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit('changeSocialUserLoading', false)
    }
  }
}

const mutations = {
  authSaveToken (state, token) {
    localStorage.setItem('accessToken', token.accessToken)
    localStorage.setItem('refreshToken', token.refreshToken)
    state.isAuthenticated = true
    state.accessToken = token.accessToken
    state.refreshToken = token.refreshToken
  },
  changeLoading (state, status) {
    state.isLoading = status
  },
  changeSocialLoading (state, status) {
    state.socialInfoLoading = status
  },
  changeConfirmLoading (state, status) {
    state.isLoadingConfirm = status
  },
  changeLoginLoading (state, status) {
    state.isLoadingLogin = status
  },
  saveOtpResponse (state, params = null) {
    state.otpResponse = params
  },
  changeAuthStatus (state, status) {
    state.isAuthenticated = status
  },
  setSocialData (state, data) {
    state.socialInfo = data
  },
  logout (state) {
    state.otpResponse = null
    state.isAuthenticated = false
    state.accessToken = null
    state.refreshToken = null
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  },
  authSaveAuthByCode (state, data) {
    state.authByCodeResponse = data
  },
  changeAuthByCodeLoading (state, status) {
    state.authByCodeLoading = status
  },
  saveUser (state, user) {
    state.user = user
  },
  changeUserLoading (state, status) {
    state.userLoading = status
  },
  changeSocialUserLoading (state, status) {
    state.userLoading = status
  },
  saveSocialProfile (state, status) {
    state.socialProfile = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
